import React, { useEffect, useState } from "react";
import { Box, GridItem } from "@chakra-ui/react";
import { Grid, Typography } from "@material-ui/core";
import unvrs_ic from "assets/img/unvrs_ic.svg";
import Facebook from "assets/img/Facebook.svg";
import twttr_ic_02 from "assets/img/twttr_ic_02.svg";
import Insta from "assets/img/Insta.svg";
import tlgrm_ic_02 from "assets/img/tlgrm_ic_02.svg";
import discord from "assets/img/discord.svg";
import Youtube from "assets/img/YouTube.svg";
import Twitch from "assets/img/Twitch.svg";
import doc_ic from "assets/img/doc_ic.svg";
import trading_view from "assets/img/trading_view.svg";

export default function ProjectOfWeek() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://oyster-app-mljpv.ondigitalocean.app/rest/projects")
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setProjects(data.projects.slice(0, 5));
      })
      .catch(() => setError(true));
  }, []);

  return (
    <Grid container spacing={0} className="justify-content-center mt-xl">
      <Grid item xs={12} lg={8}>
        <Grid container spacing={3}>
          {loading && (
            <GridItem colSpan={[12, 12, 6, 4]}>
              <Box className="cntmt_info_btn_prnt">
                <Typography
                  style={{ fontSize: 32, textAlign: "center", width: "100%" }}
                  component="h2"
                  align="center"
                >
                  Loading...
                </Typography>
              </Box>
            </GridItem>
          )}
          {projects?.map((project, index) => (
            <Project key={index} project={project} />
          ))}
          {error && (
            <GridItem colSpan={[12, 12, 6, 4]}>
              <Box className="cntmt_info_btn_prnt">
                <Typography
                  style={{ fontSize: 32, textAlign: "center", width: "100%" }}
                  component="h2"
                  align="center"
                >
                  Error while fetching data...
                </Typography>
              </Box>
            </GridItem>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

function Project({ project }) {
  const LINK_BTNS = [
    { image: unvrs_ic, url: project.website },
    { image: Facebook, url: project.facebook },
    { image: twttr_ic_02, url: project.twitter },
    { image: Insta, url: project.instagram },
    { image: tlgrm_ic_02, url: project.telegram },
    { image: discord, url: project.discord },
    { image: Youtube, url: project.youtube },
    { image: Twitch, url: project.twitch },
    { image: doc_ic, url: project.linkedIn },
    { image: trading_view, url: project.tradingview },
  ].filter((item) => !!item.url);

  return (
    <GridItem colSpan={[12, 12, 6, 4]}>
      <Box className="cntmt_info_btn_prnt">
        <section className="Projects_launchSection__3CB6I ">
          <div className="Projects_list__3Pkjg">
            <ul className="ProjectList_items__2lWio">
              <div
                className="ProjectItem_container__A3oQr"
                style={{ cursor: "default" }}
              >
                <div className="ProjectItem_banner__5lVHt">
                  <div className="">
                    {project.profileSourceType === "image" ? (
                      <img
                        className="img-fluid"
                        src={`/projects/${project.profileSource || "oir.png"}`}
                        alt={project.name}
                      />
                    ) : (
                      <video
                        className="img-fluid"
                        autoPlay="manually"
                        loop="loop"
                        muted="muted"
                        src={`/projects/${project.profileSource || "oir.mp4"}`}
                      ></video>
                    )}
                  </div>
                </div>

                <Box mx={32} my={12}>
                  <Typography
                    className="text-secondary"
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                    }}
                    component="h3"
                    gutterBottom
                  >
                    {project.name}
                  </Typography>
                  <Typography
                    className="text-white"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    {project.description}
                  </Typography>

                  <Box
                    display="flex"
                    gap={12}
                    py={24}
                    flexWrap="wrap"
                    justifyContent="center"
                  >
                    {LINK_BTNS.map((link, index) => (
                      <a href={link.url} key={index}>
                        <img src={link.image} alt="" height={36} width={36} />
                      </a>
                    ))}
                  </Box>
                </Box>
              </div>
            </ul>
          </div>
        </section>
      </Box>
    </GridItem>
  );
}
