import React from "react";
import FirstSc from "./FirstSc";
import AOS from "aos";
import { Heading } from "@chakra-ui/react";
import { Grid, Box } from "@material-ui/core";
import { Element } from "react-scroll";
import CompetitionBoxes from "features/competitions/sections/CompetitionBoxes";
import CompetitionBoxesV2 from "features/competitionsV2/sections/CompetitionBoxesV2";

export default function Landing() {
  AOS.init();
  return (
    <>
      <div className="landing-page">
        <div className="wrapper" style={{ overflow: "hidden" }}>
          <FirstSc />
          <Element name="GallarySc">
            <Box className="portfolio_gallary_section">
              <Box
                className="gallary_sect"
                data-aos="zoom-in-down"
                data-aos-duration="2000"
              >
                <Heading as="h4">Competition</Heading>
                <Grid item xs={12} lg={12} className="xx">
                  <Heading as="h2">
                    <span></span>
                  </Heading>
                </Grid>
              </Box>
              <Grid item xs={12}>
                <Grid container spacing={8} className="justify-content-center">
                  <Grid item xs={12} lg={8}>
                    <Grid container>
                      <CompetitionBoxesV2 />
                      <CompetitionBoxes />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Element>
        </div>
      </div>
    </>
  );
}
