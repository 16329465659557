import React, { useEffect, useState } from "react";

import { SnackbarProvider } from "notistack";
import { useConnectWallet, useDisconnectWallet } from "./redux/hooks";
import { createWeb3Modal } from "../web3";
import NetworksProvider from "components/NetworksProvider/NetworksProvider";
import Header from "components/Header/Header";
import { Notifier } from "features/common";

export default function App({ children }) {
  const {
    connectWallet,
    web3,
    address,
    networkId,
    connected,
    connectWalletPending,
  } = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);

  useEffect(() => {
    setModal(createWeb3Modal());
  }, [setModal]);

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet]);

  // Mozilla Bug by network request if you change the tab to another web3 Website
  useEffect(() => {
    if (
      web3 &&
      address &&
      !connectWalletPending &&
      Boolean(networkId !== 56) &&
      Boolean(networkId !== 1) &&
      Boolean(networkId !== 97)
    ) {
      alert("Please connect to ETH Network!");
    }
  }, [web3, address, networkId, connectWalletPending]);

  return (
    <>
       <NetworksProvider>
          <SnackbarProvider>
            <div>
              <Header
                address={address}
                connected={connected}
                connectWallet={() => connectWallet(web3Modal)}
                disconnectWallet={() => disconnectWallet(web3, web3Modal)}
              />
              <div>
                <div>
                  {children}
                  <Notifier />
                </div>
              </div>
            </div>
          </SnackbarProvider>
        </NetworksProvider>
    </>
  );
}
