import React, { useEffect } from "react";
import AOS from "aos";
import { Heading } from "@chakra-ui/react";
import { Grid, Box } from "@material-ui/core";

import { Element } from "react-scroll";
import CircleBoxes6 from "./sections/CircleBoxes6";
import FirstSc from "pages/FirstSc";

export default function VaultPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  AOS.init();
  return (
    <>
      <div className="landing-page">
        <div className="wrapper" style={{ overflow: "hidden" }}>
          <FirstSc />
          <Element name="GallarySc">
            <Box className="portfolio_gallary_section">
              <Box
                className="gallary_sect"
                data-aos="zoom-in-down"
                data-aos-duration="2000"
              >
                <Heading as="h4">Claim Nft Rewards</Heading>
              </Box>
              <Grid item xs={12}>
                <CircleBoxes6 />
              </Grid>
            </Box>
          </Element>
        </div>
      </div>
    </>
  );
}
