import { erc20ABI, nftnew, nft6 } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
import { ethers } from "ethers";
export { useFetchPoolBalances } from "./fetchPoolBalances";

export function useBalanceOf(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useDeposit(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (id, MP1) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(nftnew, poolAddress);
          contract.methods
            .buyOne(id)
            .send({ from: address, value: MP1 })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useDeposit3(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending3, setIsPending3] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit3 = useCallback(
    async (id, MP1) => {
      setIsPending3(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(nft6, poolAddress);
          contract.methods
            .buyOne(id)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending3(false);
      }
    },
    [dispatch, setIsPending3, web3, address, poolAddress]
  );

  return { isPending3, onDeposit3: handleDeposit3 };
}

export function useDeposit2(poolAddress, tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending2, setIsPending2] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit2 = useCallback(
    async (id, MP2) => {
      setIsPending2(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(nftnew, poolAddress);

          contract.methods
            .buyThree(id)
            .send({ from: address, value: MP2 })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending2(false);
      }
    },
    [dispatch, setIsPending2, web3, address, poolAddress]
  );

  return { isPending2, onDeposit2: handleDeposit2 };
}

export function useDeposit4(poolAddress, tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending4, setIsPending4] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit4 = useCallback(
    async (id) => {
      setIsPending4(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(nft6, poolAddress);

          contract.methods
            .buyThree(id)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending4(false);
      }
    },
    [dispatch, setIsPending4, web3, address, poolAddress]
  );

  return { isPending4, onDeposit4: handleDeposit4 };
}


export function useEntry(poolAddress, id) {
  const { address, web3 } = useConnectWallet();
  const [Entry, setEntry] = useState("");

  const fetchEntry = useCallback(async () => {
    const contract = new web3.eth.Contract(nftnew, poolAddress);

    const Entry = await contract.methods
      .getContestEntries(id)
      .call({ from: address });
    setEntry(Entry);
  }, [address, setEntry, poolAddress, web3, id]);

  useEffect(() => {
    if (web3 && address) {
      fetchEntry();

      let refreshInterval = setInterval(fetchEntry, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEntry]);
  return Entry;
}

export function useUEntry(poolAddress, id) {
  const { address, web3 } = useConnectWallet();
  const [UEntry, setUEntry] = useState("");

  const fetchUEntry = useCallback(async () => {
    const contract = new web3.eth.Contract(nftnew, poolAddress);

    const UEntry = await contract.methods
      .getUserEntryCount(id, address)
      .call({ from: address });
    setUEntry(UEntry);
  }, [address, setUEntry, poolAddress, web3, id]);

  useEffect(() => {
    if (web3 && address) {
      fetchUEntry();

      let refreshInterval = setInterval(fetchUEntry, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchUEntry]);
  return UEntry;
}

export function useUContest(poolAddress, id) {
  const { address, web3 } = useConnectWallet();
  const [UContest, setUContest] = useState("");

  const fetchUContest = useCallback(async () => {
    const contract = new web3.eth.Contract(nftnew, poolAddress);

    const UContest = await contract.methods
      .Contests(id)
      .call({ from: address });
    setUContest(UContest);
  }, [address, setUContest, poolAddress, web3, id]);

  useEffect(() => {
    if (web3 && address) {
      fetchUContest();

      let refreshInterval = setInterval(fetchUContest, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchUContest]);
  return UContest;
}

export function useAllowance2(tokenAddress, spender) {
  const { web3, address } = useConnectWallet();
  const [allowance2, setAllowance2] = useState("0");

  const fetchAllowance2 = useCallback(async () => {
    if (tokenAddress === "") {
      setAllowance2(ethers.constants.MaxUint256.toString(10));
      return;
    }

    const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

    const allowance2 = await contract.methods
      .allowance(address, spender)
      .call();
    setAllowance2(allowance2);
  }, [address, spender, setAllowance2, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance2();

      let refreshInterval = setInterval(fetchAllowance2, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance2]);

  return allowance2;
}

export function useApprove2(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        contract.methods
          .approve(poolAddress, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove2: handleApprove };
}

export function useWinner(poolAddress, id) {
  const { address, web3 } = useConnectWallet();
  const [Winner, setWinner] = useState("");

  const fetchWinner = useCallback(async () => {
    const contract = new web3.eth.Contract(nftnew, poolAddress);

    const Winner = await contract.methods
      .ContestWinner(id)
      .call({ from: address });
    setWinner(Winner);
  }, [address, setWinner, poolAddress, web3, id]);

  useEffect(() => {
    if (web3 && address) {
      fetchWinner();

      let refreshInterval = setInterval(fetchWinner, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchWinner]);
  return Winner;
}
