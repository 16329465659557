//AUTO CONTRACT: 0xC5Be0C94C6A166F9ee28e56F5327006988f4291A
//MANUAL CONTRACT: 0x9d808bD8E24C8b8093Cd1A166e7cD9669FBD3359
//MANUAL CONTRACT V2: 0x887510d41879D3c1bD77E7d6fE977E659A59d532
//MANUAL CONTRACT ETH: 0x62C0F04eef81259659A153f2c47427Dacc770D85
//TokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", // busd
//Native: 0x0000000000000000000000000000000000000000
//NEW COMP: 0x2b058017a4202324A9Bc3dd9A6651E7d564Ba507

export const competitionsV2 = [
  
  {
    chainId: 56,
    id: "1",
    tokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    tokenDecimals: 18,
    earnContractAddress: "0x2b058017a4202324A9Bc3dd9A6651E7d564Ba507", //auto or manual competition
    desc: "Weekly Wednesday Draw", // <-- this overwrites the line "payment"
    paymentlogo: "BUSD-logo.png",
    payment: "BUSD",
    chain: "BSC",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  },

  /*   {
    chainId: 1,
    id: "5",
    tokenAddress: "",
    tokenDecimals: 18,
    earnContractAddress: "0x62C0F04eef81259659A153f2c47427Dacc770D85", //auto or manual competition
    desc: "PirateApe #2069 + Small Rum", // <-- this overwrites the line "payment"
    paymentlogo: "APES-logo.png",
    payment: "ETH",
    chain: "ETH",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  },

  {
    chainId: 1,
    id: "3",
    tokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707",
    tokenDecimals: 18,
    earnContractAddress: "0x62C0F04eef81259659A153f2c47427Dacc770D85", //auto or manual competition
    desc: "Pi E-Circle Utility NFT", // <-- this overwrites the line "payment"
    paymentlogo: "ECIRCLE-logo.png",
    payment: "PIP",
    chain: "ETH",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*   {
    chainId: 56,
    id: "27",
    tokenAddress: "",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "Shiba Heist Mystery Box (3 NFT'S)", // <-- this overwrites the line "payment"
    paymentlogo: "SHIB-logo.png",
    payment: "BNB",
    chain: "BSC",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*   {
    chainId: 56,
    id: "22",
    tokenAddress: "",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "5 Pi Phoenix Nft's", // <-- this overwrites the line "payment"
    paymentlogo: "PHEONIXNFT-logo.png",
    payment: "BNB",
    chain: "BSC",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*   {
    chainId: 56,
    id: "8",
    tokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "2 Pi Scircles", // <-- this overwrites the line "payment"
    paymentlogo: "SCIRCLE-logo.png",
    payment: "BUSD",
    chain: "BSC",
    winner: "0x7974b551B08Ed69b1446d9f46302fa05dcaDB71b", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*   {
    chainId: 56,
    id: "11",
    tokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "1 Óir Utility Nft", // <-- this overwrites the line "payment"
    paymentlogo: "OIRR-logo.png",
    payment: "BUSD",
    chain: "BSC",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*   {
    chainId: 56,
    id: "19",
    tokenAddress: "0x25c30340e6f9f6e521827cf03282943da00c0ece",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "1 Pi Scircle", // <-- this overwrites the line "payment"
    paymentlogo: "SCIRCLE-logo.png",
    payment: "PIP",
    chain: "BSC",
    winner: "0xD9870A9677778Db6Da1da141D9FaF3CC8C1DBe15", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*     {
    chainId: 56,
    id: "13",
    tokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "1 Pi Scircle", // <-- this overwrites the line "payment"
    paymentlogo: "SCIRCLE-logo.png",
    payment: "BUSD",
    chain: "BSC",
    winner: "0xc9AAac8AF688bDaE43C172f87d9852E8492fe073", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*   {
    chainId: 56,
    id: "10",
    tokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "2 Pi Scircles", // <-- this overwrites the line "payment"
    paymentlogo: "SCIRCLE-logo.png",
    payment: "BUSD",
    chain: "BSC",
    winner: "0x7974b551B08Ed69b1446d9f46302fa05dcaDB71b", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

  /*   {
    chainId: 56,
    id: "9",
    tokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    tokenDecimals: 18,
    earnContractAddress: "0x887510d41879D3c1bD77E7d6fE977E659A59d532", //auto or manual competition
    desc: "1 Pi Eth Silver", // <-- this overwrites the line "payment"
    paymentlogo: "ETHSILVER-logo.png",
    payment: "BUSD",
    chain: "BSC",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */
];
