export const nftpool = [
  {
    chainId: 56,
    id: "0",
    name: "",
    token: "",
    tokenDescription: "",
    tokenAddress: "", // Staking Token 1
    tokenDecimals: 18,
    claimedTokenAddress: "", // Reward Token
    earnedTokenAddress: "", // Reward Token
    earnContractAddress: "0xa9042d743437a688d94FE000e0eb20bcD5beb74A", // Golden ticket
    status: "aktive",
  },
  
];
