import React from "react";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";

function CompetitionBoxesV2() {
  let { competitionsV2 } = useFetchPoolBalances();

  return (
    <>
      {competitionsV2.map((npool, index) => (
        <PoolContent key={index} npool={npool} />
      ))}
    </>
  );
}

export default CompetitionBoxesV2;
