import React from "react";
import { SocialButtons } from "components/SocialButtons";
import { Row, Col, Container } from "reactstrap";
import video1 from "../assets/video/1.mp4";
export default function FirstSc() {
  return (
    <>
      <div >
        <div className="header header-4">
          <div className="header-wrapper">
            <div className="page-header header-video header-filter">
              <div />
              <video autoPlay="autoplay" loop="loop" muted="muted" width="100%">
                <source src={video1} type="video/mp4" />
              </video>
              <Container>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Row>
                  <Col xs={12} md={4}></Col>
                  <Col xs={12} md={4}>
                    <div className="mr-auto text-center mt-5" lg="5" md="7">
                      <img
                        alt="..."
                        max-width="100%"
                        src={require("assets/img/prism.png")}
                        style={{ zIndex: 5 }}
                      />
                      <SocialButtons />
                    </div>
                  </Col>
                  <Col xs={6} md={4}></Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
