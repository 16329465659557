import { competitionsV2 } from "../../configure";

const tokens = {
  97: {},
  1: {},
  56: {},

};

competitionsV2.map(({chainId, token, tokenAddress })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  competitionsV2,
  tokens,
};

export default initialState;