//  connectWallet
export const HOME_CONNECT_WALLET_BEGIN = "HOME_CONNECT_WALLET_BEGIN";
export const HOME_CONNECT_WALLET_SUCCESS = "HOME_CONNECT_WALLET_SUCCESS";
export const HOME_CONNECT_WALLET_FAILURE = "HOME_CONNECT_WALLET_FAILURE";
export const HOME_ACCOUNTS_CHANGED = "HOME_ACCOUNTS_CHANGED";
export const HOME_NETWORK_CHANGED = "HOME_NETWORK_CHANGED";
//  disconnectWallet
export const HOME_DISCONNECT_WALLET_BEGIN = "HOME_DISCONNECT_WALLET_BEGIN";
export const HOME_DISCONNECT_WALLET_SUCCESS = "HOME_DISCONNECT_WALLET_SUCCESS";
export const HOME_DISCONNECT_WALLET_FAILURE = "HOME_DISCONNECT_WALLET_FAILURE";
