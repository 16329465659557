import { useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  NFT_FETCH_POOL_BALANCES_BEGIN,
  NFT_FETCH_POOL_BALANCES_SUCCESS,
  NFT_FETCH_POOL_BALANCES_FAILURE,
} from "./constants";

export function useFetchPoolBalances() {
  // args: false value or array
  // if array, means args passed to the action creator

  const { competitions, fetchPoolBalancesPending } = useSelector(
    (state) => ({
      competitions: state.competitions.competitions,
      fetchPoolBalancesPending: state.competitions.fetchPoolBalancesPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback((data) => {}, []);

  return {
    competitions,
    fetchPoolBalances: boundAction,
    fetchPoolBalancesPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case NFT_FETCH_POOL_BALANCES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolBalancesPending: true,
      };

    case NFT_FETCH_POOL_BALANCES_SUCCESS:
      // The request is success
      return {
        ...state,
        nftpools: action.data,
        fetchPoolBalancesPending: false,
      };

    case NFT_FETCH_POOL_BALANCES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolBalancesPending: false,
      };

    default:
      return state;
  }
}
