import { Faq } from '.';


export default {
  path: "faq",
  childRoutes: [
    { path: "faq", component: Faq, isIndex: true },

  ],
};
