import { nftpool } from "../../configure";

const tokens = {
  56: {},
  3: {},
  1: {}

};

nftpool.map(({chainId, token, tokenAddress })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  nftpool,
  tokens,
};

export default initialState;