import React from "react";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";

function CompetitionBoxes() {
  let { competitions } = useFetchPoolBalances();

  return (
    <>
      {competitions.map((npool, index) => (
        <PoolContent key={index} npool={npool} />
      ))}
    </>
  );
}

export default CompetitionBoxes;
