import React, { useEffect, useCallback, useState } from "react";
import { Box, Button, GridItem, Image } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
//import { byDecimals } from "features/helpers/bignumber";
import { nft1 } from "features/configure/abi";
import { useConnectWallet } from "../../home/redux/hooks";
import { byDecimals } from "features/helpers/bignumber";

import {
  useDeposit,
  usePrice,
  useActive,
  //useMaxMint,
  useSupply,
  useBalanceOf,
} from "../redux/hooks";
import { Grid, Typography } from "@material-ui/core";
import video2 from "assets/video/3.mp4";

export default function PoolContent({ index, npool }) {
  const { web3, address } = useConnectWallet();
  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );
  const [reward, setReward] = useState("0");

  const fetchreward = useCallback(async () => {
    const contract = new web3.eth.Contract(nft1, npool.earnContractAddress);
    const reward = await contract.methods
      .getRewardBalance(address)
      .call({ from: address });
    setReward(reward);
  }, [web3, npool.earnContractAddress, address]);

  const price = usePrice(npool.earnContractAddress);
  const active = useActive(npool.earnContractAddress);
  // const maxmint = useMaxMint(npool.earnContractAddress);
  const supply = useSupply(npool.earnContractAddress);
  const balance = useBalanceOf(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  useEffect(() => {
    if (web3 && address && balance > 0) {
      fetchreward();
      let refreshInterval = setInterval(fetchreward, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchreward, balance]);

  return (
    <>
      <GridItem colSpan={[12, 12, 6, 4]}>
        <Box
          className="cntmt_info_btn_prnt"
          data-aos="fade-up"
          data-aos-duration="2400"
        >
          <section class="Projects_launchSection__3CB6I ">
            <div class="Projects_list__3Pkjg">
              <ul class="ProjectList_items__2lWio">
                <div class="ProjectItem_container__A3oQr ProjectItem_comingSoon__15q_U">
                  <div class="ProjectItem_banner__5lVHt">
                    <div class="">
                      <video
                        className="img-fluid"
                        autoPlay="manually"
                        loop="loop"
                        muted="muted"
                      >
                        <source src={video2} type="video/mp4" />
                      </video>
                    </div>
                  </div>

                  <div class="ProjectItem_body__3OXxq">
                    <div class="ProjectItem_head__-MZNv">
                      <Box className="itm_btminfo_v2">
                        <Grid container spacing={0}>
                          <Grid item xs={12} lg={12}>
                            {/*                             <Typography component="p">
                              Price:{" "}
                              <span> {forMat(byDecimals(price), 18)}</span> BNB
                            </Typography> */}
                            <Typography component="p">
                              Total Supply: <span> {supply}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            {/*                             <Typography component="p">
                              Max per Address: <span>{maxmint}</span>
                            </Typography> */}
                          </Grid>
                        </Grid>

                        <Grid container spacing={0}>
                          <Grid item xs={12} lg={12}>
                            <Typography component="p">
                              {" "}
                              Description: <br></br>
                              <br></br>
                              <span>
                                {" "}
                                Only 200 ÓIR Utility NFT’s will ever exist
                                <br></br>
                                <br></br>
                                Each NFT held will receive 0.5% of the projects
                                distributed profits that can be claimed on a
                                weekly basis.<br></br>
                                <br></br>
                                No lock-in period for staking<br></br>
                                <br></br>
                                Profit distribution <br></br>
                                <br></br>
                                60% of profits will be distributed weekly to NFT
                                holders
                                <br></br>
                                20% of Profits to Marketing and Development
                                wallet
                                <br></br>
                                20% of profits to the team
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                    <Grid container spacing={0}>
                      <Grid item xs={12} lg={12}>
                        <Typography component="p">
                          {" "}
                          Claimable reward: <br></br>
                          {balance > 0 ? forMat(byDecimals(reward), 18) : "-"} 
                          {" "} BNB
                        </Typography>
                        <Typography>Your NFTs: {balance}</Typography>
                      </Grid>
                    </Grid>

                    <div class="ProjectItem_label__1V6Io">Sale Active?</div>
                    <div class="ProjectItem_progress__1ZBnK">
                      {active === true ? (
                        <div class="ProgressBar_container__5i1La2" />
                      ) : (
                        <div class="ProgressBar_container__5i1La2a" />
                      )}

                      <div class="ProgressDetail_progressDetail__2H2xK">
                        <Box className="">
                          <Box className="blr_box">
                            <Box className="teresr_two_btn">
                              <Box className="buy_btn_min_box buy_min_box">
                                <Image
                                  src={require("assets/img/prism2.png")}
                                  alt=""
                                  width="30px"
                                  className="buy_nft_btn_img"
                                />
                                <Button
                                  className="buy_nft_btn"
                                  onFocus={(event) => event.stopPropagation()}
                                  disabled={isDepositPending}
                                  onClick={(event) => {
                                    onDeposit(price);
                                  }}
                                >
                                  Claim rewards
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </section>
        </Box>
      </GridItem>
    </>
  );
}
