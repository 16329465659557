import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";
import homeReducer from "features/home/redux/reducer";
import competitionsV2Reducer from "features/competitionsV2/redux/reducer";
import competitionsReducer from "features/competitions/redux/reducer";
import nftReducer from "features/nft/redux/reducer";
import commonReducer from "features/common/redux/reducer";

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  competitionsV2: competitionsV2Reducer,
  competitions: competitionsReducer,
  nft: nftReducer,
};

export default combineReducers(reducerMap);
