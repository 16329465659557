import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import pic1 from "../../assets/img/yllw_bg_arrw.svg";

export default function Faq() {
  return (
    <>
      <Box className="faq_main_box footer_spaceing">
      
        <Container className="container">

          <Grid item xs={12} lg={12}>
          <br></br>
          <br></br>
            <Box className="acrdng_main_box">
              <Box className="lft_cntnt">
                <Box className="brdr_bx">
                  <Box className="yllw_bx">
                    <Typography className="nmbr_txt">1</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="rght_cntnt">
                <Accordion className="grdnbrdr_bx">
                  <AccordionSummary
                    expandIcon={<Box component="img" src={pic1} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      What wallet can I use to buy a ticket?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Metamask, SafePal and all Trustwallet related wallets.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box className="acrdng_main_box">
              <Box className="lft_cntnt">
                <Box className="brdr_bx">
                  <Box className="yllw_bx">
                    <Typography className="nmbr_txt">2</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="rght_cntnt">
                <Accordion className="grdnbrdr_bx">
                  <AccordionSummary
                    expandIcon={<Box component="img" src={pic1} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>How are the winners selected?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Winners are selected using an automated random number
                      generator.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box className="acrdng_main_box">
              <Box className="lft_cntnt">
                <Box className="brdr_bx">
                  <Box className="yllw_bx">
                    <Typography className="nmbr_txt">3</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="rght_cntnt">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Box component="img" src={pic1} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>How will I know if I won?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      All transactions including the winner’s pay-out will be
                      visible on chain. The winning address will also be
                      announced on all our social media accounts (Twitter,
                      Telegram and Facebook).
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box className="acrdng_main_box">
              <Box className="lft_cntnt">
                <Box className="brdr_bx">
                  <Box className="yllw_bx">
                    <Typography className="nmbr_txt">4</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="rght_cntnt">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Box component="img" src={pic1} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      How long is the competition open for?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      There is no set timeframe for the competitions to run.
                      Competitions will run until all tickets are sold. Once all
                      tickets are sold, a winner will be automatically selected,
                      and the competition will reset.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box className="acrdng_main_box">
              <Box className="lft_cntnt">
                <Box className="brdr_bx">
                  <Box className="yllw_bx">
                    <Typography className="nmbr_txt">5</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="rght_cntnt">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Box component="img" src={pic1} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>What are my chances of winning?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      All competitions have a maximum number of entries. Thus,
                      you can calculate your chances of winning easily. A
                      competition with 100 entries gives you a 1 in 100 chance
                      of winning with a single. You can increase your chances of
                      winning by buying more tickets, for example in the
                      100-entry competition outlined above you would have a 1 in
                      10 chance of winning with 10 tickets bought.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box className="acrdng_main_box">
              <Box className="lft_cntnt">
                <Box className="brdr_bx">
                  <Box className="yllw_bx">
                    <Typography className="nmbr_txt">6</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="rght_cntnt">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Box component="img" src={pic1} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Can I get a refund of my entry fee?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      We do not offer refunds of entry fees. Once you enter a
                      competition, your funds will be active in an automated
                      smart contract that will finalise at the end of the
                      competition.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box className="acrdng_main_box">
              <Box className="lft_cntnt">
                <Box className="brdr_bx">
                  <Box className="yllw_bx">
                    <Typography className="nmbr_txt">7</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="rght_cntnt">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<Box component="img" src={pic1} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>My question was not answered here….</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Please feel free to contact us via any of our social media
                      accounts for further information:{" "}
                      <a href="https://t.me/OirOfficial">https://t.me/OirOfficial</a>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
