import React, { useState, useEffect, useRef } from "react";

import {
  Button,
  Collapse,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { renderIcon } from "@download/blockies";

export default function ColorNavBar(props) {
  const { connected, address, connectWallet, disconnectWallet } = props;

  const [isCollapsed, setCollapsed] = useState(true);
  const canvasRef = useRef(null);
  const [shortAddress, setShortAddress] = useState("");
  const [dataUrl, setDataUrl] = useState(null);

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current;
    renderIcon({ seed: address.toLowerCase() }, canvas);
    const updatedDataUrl = canvas.toDataURL();
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl);
    }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
    }
  }, [dataUrl, address, connected]);


  return (
    <Navbar className={"as_nvbr fixed-top " + navbarColor} expand="lg">
      <Container>
        <div className="navbar-translate">
          <button
            aria-expanded={!isCollapsed}
            className="navbar-toggler navbar-toggler"
            onClick={() => setCollapsed(!isCollapsed)}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-center "}
          isOpen={!isCollapsed}
          navbar
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                OIR
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={!isCollapsed}
                  className="navbar-toggler"
                  onClick={() => setCollapsed(!isCollapsed)}
                >
                  <span className="navbar-toggler-bar bar4" />
                  <span className="navbar-toggler-bar bar5" />
                  <span className="navbar-toggler-bar bar6" />
                </button>
              </Col>
            </Row>
          </div>

          <Nav navbar>

          <NavItem>
              <Link
                className="nav-link"
                to="/"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">Competition</span>{" "}
              </Link>
            </NavItem>


{/*             <NavItem>
              <Link
                className="nav-link"
                to="/comp"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">Competition</span>{" "}
              </Link>
            </NavItem> */}

            <NavItem>
              <Link
                className="nav-link"
                to="/nft"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">NFT</span>
              </Link>
            </NavItem>

            <NavItem>
              <Link
                className="nav-link"
                to="/oirbitblaster"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">ÓIRCADE</span>
              </Link>
            </NavItem>

{/*             <NavItem>
              <Link
                className="nav-link"
                to="/project-of-the-week"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">Featured Projects</span>
              </Link>
            </NavItem> */}

            <NavItem>
              <Link 
                className="nav-link"
                to="/faq"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">FAQ</span>{" "}
              </Link>
            </NavItem>


            {/*   <NavItem>
              <Link
                className="nav-link"
                to="news"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">NEWS</span>
              </Link>
            </NavItem> */}

            {/* 
            <NavItem>
              <Link
                className="nav-link"
                to="/faq"
                onClick={() => !isCollapsed && setCollapsed(true)}
              >
                <span className="d-block">FAQ</span>
              </Link>
            </NavItem> */}

            <Button
              className="hdrwlltbtn"
              type="button"
              //color="warning"
              onClick={connected ? disconnectWallet : connectWallet}
            >
              {connected ? (
                <>
                  {" "}
                  {""}
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  {shortAddress}
                </>
              ) : (
                <>{"Wallet"}</>
              )}
            </Button>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
