import { App } from "../features/home";
import homeRoute from "../features/home/route";
import competitionsV2Route from "../features/competitionsV2/route";
import competitionsRoute from "../features/competitions/route";
import nftRoute from "../features/nft/route";
import faqRoute from "../features/faq/route";

import commonRoute from "../features/common/route";
import _ from "lodash";
import oirbitblasterRoute from "features/oirbitblaster/route";
import projectOfWeekRoute from "features/project-of-week/route";

const childRoutes = [homeRoute, commonRoute, competitionsV2Route, nftRoute, faqRoute, competitionsRoute, oirbitblasterRoute, projectOfWeekRoute];

const routes = [
  {
    path: "/",
    component: App,
    childRoutes: [...childRoutes].filter(
      (r) => r.component || (r.childRoutes && r.childRoutes.length > 0)
    ),
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child) => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = "";
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
