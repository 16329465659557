import React from 'react';
import { Box, GridItem } from '@chakra-ui/react';
import { Button, Grid, Typography } from '@material-ui/core';

export default function oirbitblaster() {
  return (
    <Grid container spacing={0} className="justify-content-center mt-xl">
      <Grid item xs={12} lg={8}>
        <Grid container spacing={3}>
          <GridItem colSpan={[12, 12, 6, 4]}>
            <Box className="cntmt_info_btn_prnt">
              <section className="Projects_launchSection__3CB6I ">
                <div className="Projects_list__3Pkjg">
                  <ul className="ProjectList_items__2lWio">
                    <div className="ProjectItem_container__A3oQr ProjectItem_comingSoon__15q_U">
                      <div className="ProjectItem_banner__5lVHt">
                        <div className="">
                          <video
                            className="img-fluid"
                            autoPlay="manually"
                            loop="loop"
                            muted="muted"
                            src='/video/game.mp4'
                          >
                          </video>
                        </div>
                      </div>

                      <div className="ProjectItem_body__3OXxq">
                        <Box className="blr_box">
                          <Box className="teresr_two_btn">
                            <Box className="buy_btn_min_box buy_min_box">
                              <Typography className="text-secondary"
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 16,
                                  textShadow: "1px 1px 4px #c39046",
                                  whiteSpace: "nowrap"
                                }}
                                component="span"
                                varient="h2"
                              >
                                ÓIRBIT BLASTER
                              </Typography>
                              <Button className="buy_nft_btn" href="https://www.spaceshooter-oir-finance.ml/" target="_blank">Play Game</Button>
                            </Box>
                          </Box>
                        </Box>
                      </div>
                    </div>
                  </ul>
                </div>
              </section>
            </Box>
          </GridItem>
        </Grid>
      </Grid>
    </Grid>
  );
}
