import { competitions } from "../../configure";

const tokens = {
  97: {},
  1: {},
  56: {},

};

competitions.map(({chainId, token, tokenAddress })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  competitions,
  tokens,
};

export default initialState;