import { useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  NFT_FETCH_POOL_BALANCES_BEGIN,
  NFT_FETCH_POOL_BALANCES_SUCCESS,
  NFT_FETCH_POOL_BALANCES_FAILURE,
} from "./constants";

export function useFetchPoolBalances() {
  // args: false value or array
  // if array, means args passed to the action creator

  const { competitionsV2, fetchPoolBalancesPending } = useSelector(
    (state) => ({
      competitionsV2: state.competitionsV2.competitionsV2,
      fetchPoolBalancesPending: state.competitionsV2.fetchPoolBalancesPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback((data) => {}, []);

  return {
    competitionsV2,
    fetchPoolBalances: boundAction,
    fetchPoolBalancesPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case NFT_FETCH_POOL_BALANCES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolBalancesPending: true,
      };

    case NFT_FETCH_POOL_BALANCES_SUCCESS:
      // The request is success
      return {
        ...state,
        competitionsV2: action.data,
        fetchPoolBalancesPending: false,
      };

    case NFT_FETCH_POOL_BALANCES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolBalancesPending: false,
      };

    default:
      return state;
  }
}
