import React from "react";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";
import { Grid } from "@material-ui/core";
import { useConnectWallet } from "../../home/redux/hooks";

function CircleBoxes6() {
  let { nftpool } = useFetchPoolBalances();
  const { networkId } = useConnectWallet();
  return (
    <>
      <Grid container spacing={0} className="justify-content-center">
        <Grid item xs={12} lg={8}>
          <Grid container spacing={3}>
            {nftpool
              .filter((nftpool) => nftpool.chainId === networkId)
              .map((npool, index) => (
                <PoolContent key={index} npool={npool} />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CircleBoxes6;
