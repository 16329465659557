import React from "react";
import { Button } from "reactstrap";
import { FaTwitter, FaTelegramPlane } from "react-icons/fa";

export const SocialButtons = () => {
  return (
    <div className="btn-wrapper">
      <div className="button-container">
        <Button
          className="btn-icon btn-simple btn-round  d-inline-flex justify-content-center align-items-center"
          color="default"
          target="_blank"
          href="https://mobile.twitter.com/OirOfficial"
        >
          <FaTwitter />
        </Button>
        <Button
          className="btn-icon btn-simple btn-round  d-inline-flex justify-content-center align-items-center"
          color="default"
          target="_blank"
          href="https://t.me/OirOfficial"

        >
          <FaTelegramPlane />
        </Button>

      </div>
    </div>
  );
};
