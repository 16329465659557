//AUTO CONTRACT: 0xC5Be0C94C6A166F9ee28e56F5327006988f4291A
//MANUAL CONTRACT: 0x9d808bD8E24C8b8093Cd1A166e7cD9669FBD3359
//TokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", // busd
//earncontractaddress is the competition contract
//LATEST ID FOR AUTO IS: 4
//LATEST ID FOR MANUAL IS: 0
// LATEST ID FOR MANUAL V2 IS: 7

export const competitions = [

/*   {
    chainId: 56,
    id: "1",
    tokenAddress: "",
    tokenDecimals: 18,
    earnContractAddress: "0xC5Be0C94C6A166F9ee28e56F5327006988f4291A",
    desc: "",
    paymentlogo: "BNB-logo.png",
    payment: "BNB",
    chain: "BSC",
    winner: "-",
    img: "1.png",
  }, */

 /*  {
    chainId: 56, //56 for bsc 1 for eth
    id: "4", // Id is for the competition
    tokenAddress: "", //the token which needs to be spend by users
    tokenDecimals: 18, // decimals
    earnContractAddress: "0xC5Be0C94C6A166F9ee28e56F5327006988f4291A", // either man or auto
    desc: "", // put in here something if the return is different f.e. NFT, circles - it overwrites "payment" field.
    paymentlogo: "BNB-logo.png", // logo for the price
    payment: "BNB", // BNB, BUSD, this is the token that will be spend by users
    chain: "BSC", // chain ID
    winner: "-", // overwrite the winner field-
    img: "1.png", // the image for the background.
  }, */

  /*  {
    chainId: 56,
    id: "0",
    tokenAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    tokenDecimals: 18,
    earnContractAddress: "0x9d808bD8E24C8b8093Cd1A166e7cD9669FBD3359", //auto or manual competition
    desc: "2 Pi bCircles",  // <-- this overwrites the line "payment"
    paymentlogo: "PI-logo.png",
    payment: "BUSD",
    chain: "BSC",
    winner: "-", //if not selected automatically input winner manually
    img: "1.png", //background image
  }, */

/*   {
    chainId: 56,
    id: "4",
    tokenAddress: "",
    tokenDecimals: 18,
    earnContractAddress: "0xC5Be0C94C6A166F9ee28e56F5327006988f4291A",
    desc: "",
    paymentlogo: "BNB-logo.png",
    payment: "BNB",
    chain: "BSC",
    winner: "-",
    img: "1.png",
  }, */

/*     {
      chainId: 56,
      id: "3",
      tokenAddress: "",
      tokenDecimals: 18,
      earnContractAddress: "0xC5Be0C94C6A166F9ee28e56F5327006988f4291A",
      desc: "",
    paymentlogo: "BNB-logo.png",
    payment: "BNB",
      chain: "BSC",
      winner: "-",
      img: "1",
    }, */
];
