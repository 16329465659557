import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, Button, GridItem, Heading, Image } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import { useConnectWallet } from "../../home/redux/hooks";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Hidden from "@material-ui/core/Hidden";
import {
  useDeposit,
  useDeposit2,
  useDeposit3,
  useDeposit4,
  useEntry,
  useUEntry,
  useUContest,
  useAllowance2,
  useApprove2,
  useWinner,
} from "../redux/hooks";
import { Grid, Typography } from "@material-ui/core";

export default function PoolContent({ index, npool }) {
  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit3, isPending: isDepositPending3 } = useDeposit3(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit4, isPending: isDepositPending4 } = useDeposit4(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const allowance2 = useAllowance2(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const { isPending: isApprovePending2, onApprove2 } = useApprove2(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const Entry = useEntry(npool.earnContractAddress, npool.id);

  const UEntry = useUEntry(npool.earnContractAddress, npool.id);
  const winner = useWinner(npool.earnContractAddress, npool.id);
  const Contest = useUContest(npool.earnContractAddress, npool.id);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  const [expanded, setExpanded] = React.useState("0");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <GridItem colSpan={[12, 12, 6, 4]}>
        <Box
          className="cntmt_info_btn_prnt"
          data-aos="fade-up"
          data-aos-duration="2400"
        >
          <section className="Projects_launchSection__3CB6I ">
            <div className="Projects_list__3Pkjg">
              <ul className="ProjectList_items__2lWio">
                <div className="ProjectItem_container__A3oQr ProjectItem_comingSoon__15q_U">
                  <Hidden smDown>
                    <div className="ProjectItem_banner__5lVHt">
                      <div>
                        <img
                          alt="..."
                          src={require(`../../../images/${npool.img}`)}
                          className="img-fluid"
                        />
                      </div>

                      <div
                        className="StatusLabel_container__3W-rb 
                    "
                      >
                        <img
                          alt="..."
                          src={require(`../../../images/${npool.paymentlogo}`)}
                          className="banner-small"
                        />
                      </div>
                    </div>
                  </Hidden>
                  {npool.chainId === networkId ? (
                    <div className="ProjectItem_body__3OXxq">
                      <div className="ProjectItem_head__-MZNv">
                        <Box className="itm_btminfo_v2">
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} className="xy">
                              <Heading as="h2">
                                <span> {Contest[1]}</span> of{" "}
                                <span>{Contest[2]}</span> slots filled!{" "}
                              </Heading>
                            </Grid>

                            <Grid item xs={12} lg={12} className="">
                              <br></br>
                              <Typography component="p">
                                {" "}
                                Contest ID: <span> {Contest[0]}</span> <br></br>
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} lg={12} className="xx">
                            <Heading as="h2">
                              {" "}
                              Prize <br></br>
                              <span>
                                {npool.desc === "" ? (
                                  <>
                                    {" "}
                                    {forMat(byDecimals(Contest[6]), 18)}{" "}
                                    {npool.payment}
                                  </>
                                ) : (
                                  <>{npool.desc}</>
                                )}
                              </span>
                            </Heading>
                          </Grid>

                          <Grid item xs={12} lg={12} className="xyzz">
                            <Heading as="h2">
                              <span> Winner:</span>
                              <br></br>
                              {winner ===
                              "0x0000000000000000000000000000000000000000"
                                ? npool.winner
                                : winner}
                            </Heading>
                          </Grid>
                        </Box>
                      </div>

                      {Contest[11] === true ? (
                        <div className="ProjectItem_label__1V6Io">Active</div>
                      ) : (
                        <div className="ProjectItem_label__1V6Io">Ended</div>
                      )}

                      <div className="ProjectItem_progress__1ZBnK">
                        {Contest[11] === true ? (
                          <div className="ProgressBar_container__5i1La2" />
                        ) : (
                          <div className="ProgressBar_container__5i1La2a" />
                        )}

                        <div className="ProgressDetail_progressDetail__2H2xK">
                          <Box className="">
                            <Box className="blr_box">
                              <Box className="teresr_two_btn">
                                <Box className="buy_btn_min_box buy_min_box">
                                  <Image
                                    src={require("assets/img/prism2.png")}
                                    alt=""
                                    width="30px"
                                    className="buy_nft_btn_img"
                                  />
                                  <Heading as="h2">
                                    1 Ticket <br></br>
                                    <span>
                                      {forMat(byDecimals(Contest[7]), 18)}{" "}
                                      {npool.payment}
                                    </span>
                                  </Heading>
                                  {allowance2 === "0" ? (
                                    <Button
                                      className="buy_nft_btn"
                                      onClick={(event) => {
                                        onApprove2();
                                      }}
                                      disabled={isApprovePending2[index]}
                                    >
                                      {isApprovePending2
                                        ? `${"Approving..."}`
                                        : `${"Approve"}`}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="buy_nft_btn"
                                      onFocus={(event) =>
                                        event.stopPropagation()
                                      }
                                      disabled={isDepositPending}
                                      onClick={handleClickOpen}
                                      // onDeposit(npool.id, Contest[7]);
                                    >
                                      Buy
                                    </Button>
                                  )}
                                </Box>

                                <Box className="buy_btn_min_box buy_min_box">
                                  <Image
                                    src={require("assets/img/prism2.png")}
                                    alt=""
                                    width="30px"
                                    className="buy_nft_btn_img"
                                  />{" "}
                                  <Heading as="h2">
                                    {" "}
                                    3 Tickets{" "}
                                    <span>
                                      {forMat(byDecimals(Contest[7] * 3), 18)}{" "}
                                      {npool.payment}
                                    </span>
                                  </Heading>
                                  {allowance2 === "0" ? (
                                    <Button
                                      className="buy_nft_btn"
                                      onClick={() => {
                                        onApprove2();
                                      }}
                                      disabled={isApprovePending2[index]}
                                    >
                                      {isApprovePending2
                                        ? `${"Approving..."}`
                                        : `${"Approve"}`}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="buy_nft_btn"
                                      onFocus={(event) =>
                                        event.stopPropagation()
                                      }
                                      disabled={isDepositPending2}
                                      onClick={handleClickOpen2}
                                    >
                                      Buy
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="ProjectItem_body__3OXxq">
                        <div className="ProjectItem_head__-MZNv">
                          <Box className="itm_btminfo_v2">
                            <Grid container spacing={0}>
                              <Grid item xs={12} lg={12}>
                                <Typography component="p">
                                  {" "}
                                  Please change network to {npool.chain} to see
                                  the contest details!
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} lg={12} className="xx">
                              <Heading as="h2">
                                {" "}
                                Prize <span> n/a {npool.payment}</span>
                              </Heading>
                            </Grid>
                          </Box>
                        </div>

                        <div className="ProjectItem_label__1V6Io">Active?</div>
                        <div className="ProjectItem_progress__1ZBnK">
                          <div className="ProgressBar_container__5i1La2b" />
                          <div className="ProgressDetail_progressDetail__2H2xK">
                            <Box className="">
                              <Box className="blr_box">
                                <Box className="teresr_two_btn">
                                  <Box className="buy_btn_min_box buy_min_box">
                                    <Image
                                      src={require("assets/img/prism2.png")}
                                      alt=""
                                      width="30px"
                                      className="buy_nft_btn_img"
                                    />
                                    <Heading as="h2">
                                      1 Ticket <br></br>
                                      <span>n/a {npool.payment}</span>
                                    </Heading>

                                    <Button
                                      className="buy_nft_btn"
                                      onClick={(event) => {
                                        onApprove2();
                                      }}
                                      disabled
                                    >
                                      wrong chain!
                                    </Button>
                                  </Box>

                                  <Box className="buy_btn_min_box buy_min_box">
                                    <Image
                                      src={require("assets/img/prism2.png")}
                                      alt=""
                                      width="30px"
                                      className="buy_nft_btn_img"
                                    />{" "}
                                    <Heading as="h2">
                                      {" "}
                                      3 Tickets <span>n/a {npool.payment}</span>
                                    </Heading>
                                    <Button
                                      className="buy_nft_btn"
                                      onClick={() => {
                                        onApprove2();
                                      }}
                                      disabled
                                    >
                                      wrong chain
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {npool.chainId === networkId ? (
                    <>
                      <div className="ProjectItem_stats__20cAA">
                        <div>
                          <div className="ProjectItem_statsLabel__fOKCh text-center">
                            Your Tickets
                          </div>
                          <div className="ProjectItem_statsLabel__fOKCh text-center">
                            {UEntry}
                          </div>
                        </div>
                        <div>
                          <div className="ProjectItem_statsLabel__fOKCh text-center">
                            Price
                          </div>
                          <div className="ProjectItem_statsLabel__fOKCh text-center">
                            {forMat(byDecimals(Contest[7]), 18)}
                          </div>
                        </div>
                        <div>
                          <div className="ProjectItem_statsLabel__fOKCh text-center">
                            Token
                          </div>
                          <div className="ProjectItem_statsLabel__fOKCh text-center">
                            <span> {npool.payment}</span>
                          </div>
                        </div>
                      </div>

                      <Box className="accordan_crcle_prnt">
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Buy History:</Typography>
                          </AccordionSummary>

                          <AccordionDetails>
                            <div>
                              {Entry && Entry.length
                                ? Entry.map((p, index) => (
                                    <>
                                      <div
                                        className="ProjectItem_stats__20cAA"
                                        key={index}
                                      >
                                        <div>
                                          <div className="ProjectItem_symbol__1yoWH">
                                            {" "}
                                            <Typography>
                                            Entry No.: {index} - 
                                            {p.user} - 
                                            {" "}
                                              {forMat(
                                                byDecimals(p.amount),
                                                18
                                              )}{" "} {npool.payment}
                                         
{/*                                               {`${p.user.slice(
                                                0,
                                                15
                                              )}..${p.user.slice(-5)}`} */}
                                            </Typography>
                                          </div>
                                        </div>
                                        
{/*                                         <div>
                                          <div className="ProjectItem_symbol__1yoWH">
                                            <Typography>
                                              {" "}
                                              {forMat(
                                                byDecimals(p.amount),
                                                18
                                              )}{" "}
                                              {npool.payment}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="ProjectItem_symbol__1yoWH">
                                            <Typography>{index}</Typography>
                                          </div>
                                        </div> */}
                                      </div>
                                    </>
                                  ))
                                : null}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </ul>
            </div>
          </section>
        </Box>
      </GridItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Who owns tesla? "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description text-center">
            1) Donald Duck <br></br>
            2) Elon Musk
          </DialogContentText>
        </DialogContent>

        {npool.tokenAddress === "" ? (
          <DialogActions>
            <Box className="buy_btn_min_box buy_min_box">
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending}
                onClick={(event) => {
                  onDeposit(npool.id, Contest[7]);
                }}
              >
                Answer 1
              </button>{" "}
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending}
                onClick={(event) => {
                  onDeposit(npool.id, Contest[7]);
                }}
              >
                Answer 2
              </button>
            </Box>
          </DialogActions>
        ) : (
          <DialogActions>
            <Box className="buy_btn_min_box buy_min_box">
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending3}
                onClick={(event) => {
                  onDeposit3(npool.id, Contest[7]);
                }}
              >
                Answer 1
              </button>{" "}
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending3}
                onClick={(event) => {
                  onDeposit3(npool.id, Contest[7]);
                }}
              >
                Answer 2
              </button>
            </Box>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Who owns tesla? "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description text-center">
            1) Donald Duck <br></br>
            2) Elon Musk
          </DialogContentText>
        </DialogContent>

        {npool.tokenAddress === "" ? (
          <DialogActions>
            <Box className="buy_btn_min_box buy_min_box">
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending2}
                onClick={(event) => {
                  onDeposit2(npool.id, Contest[7] * 3);
                }}
              >
                Answer 1
              </button>{" "}
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending2}
                onClick={(event) => {
                  onDeposit2(npool.id, Contest[7] * 3);
                }}
              >
                Answer 2
              </button>
            </Box>
          </DialogActions>
        ) : (
          <DialogActions>
            <Box className="buy_btn_min_box buy_min_box">
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending4}
                onClick={(event) => {
                  onDeposit4(npool.id, Contest[7] * 3);
                }}
              >
                Answer 1
              </button>{" "}
              <button
                className=""
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending4}
                onClick={(event) => {
                  onDeposit4(npool.id, Contest[7] * 3);
                }}
              >
                Answer 2
              </button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
