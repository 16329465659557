import { erc20ABI, nft1 } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
export { useFetchPoolBalances } from "./fetchPoolBalances";

export function useBalanceOf(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useDeposit(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (MP1) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(nft1, poolAddress);
          contract.methods
            .claim()
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function usePrice(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [Price, setPrice] = useState("");

  const fetchPrice = useCallback(async () => {
    const contract = new web3.eth.Contract(nft1, poolAddress);

    const Price = await contract.methods.price().call({ from: address });
    setPrice(Price);
  }, [address, setPrice, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchPrice();

      let refreshInterval = setInterval(fetchPrice, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchPrice]);
  return Price;
}

export function useActive(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [Active, setActive] = useState("");

  const fetchActive = useCallback(async () => {
    const contract = new web3.eth.Contract(nft1, poolAddress);

    const Active = await contract.methods.saleActive().call({ from: address });
    setActive(Active);
  }, [address, setActive, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchActive();

      let refreshInterval = setInterval(fetchActive, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchActive]);
  return Active;
}

export function useMaxMint(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [maxMint, setmaxMint] = useState("");

  const fetchmaxMint = useCallback(async () => {
    const contract = new web3.eth.Contract(nft1, poolAddress);

    const maxMint = await contract.methods
      .maxPerAddress()
      .call({ from: address });
    setmaxMint(maxMint);
  }, [address, setmaxMint, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchmaxMint();

      let refreshInterval = setInterval(fetchmaxMint, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchmaxMint]);
  return maxMint;
}

export function useSupply(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [Supply, setSupply] = useState("");

  const fetchSupply = useCallback(async () => {
    const contract = new web3.eth.Contract(nft1, poolAddress);

    const Supply = await contract.methods.totalSupply().call({ from: address });
    setSupply(Supply);
  }, [address, setSupply, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);
  return Supply;
}